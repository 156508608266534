<template>
  <Tabs v-model="parcelTabs" layout="simple">
    <Tab title="Informations">
      <Container>
        <h3>Informations</h3>
      </Container>
      <div class="key-values key-values--footer-bottom">
        <KeyValue table label="Nom de la parcelle" required type="input">
          <Input id="designation" required />
        </KeyValue>
        <KeyValue table label="Nom de l'agriculteur" type="input">
          <Input id="nom_agriculteur" />
        </KeyValue>
        <KeyValue table label="Adresse" type="input">
          <Input id="adresse" />
        </KeyValue>
        <KeyValue table label="Code postal" type="input">
          <Input id="cp" />
        </KeyValue>
        <KeyValue table label="Ville" type="input">
          <Input id="ville" />
        </KeyValue>
        <KeyValue table label="Téléphone" type="input">
          <Input id="tel" />
        </KeyValue>
        <KeyValue table label="Mail" type="input">
          <Input id="email" />
        </KeyValue>
        <KeyValue
          v-if="!$route.name.includes('essai')"
          table
          label="Plateforme d'essais"
          type="input"
        >
          <Radio
            id="plateforme"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Latitude"  type="input">
          <Input id="latitude" required textInfo="DD : xx.xxxxxx" />
        </KeyValue>
        <KeyValue table label="Longitude"  type="input">
          <Input id="longitude" required textInfo="DD : xx.xxxxxx" />
        </KeyValue>
        <KeyValue
          table
          label="Ville de la station météo la plus proche"
          type="input"
        >
          <Input id="villemeteo" />
        </KeyValue>
        <KeyValue
          table
          label="Code postal de la station météo la plus proche"
          type="input"
        >
          <Input id="cpmeteo" />
        </KeyValue>

        <KeyValue table label="Plan de la parcelle" type="input">
          <InputMedia
            id="media"
            type="plan"
            textInfo="Fichier de 30Mo maximum"
            :media="parcel.media ?? null"
            @update:modelValue="setPlanFile($event)"
            :defaultImage="true"
          />
        </KeyValue>
      </div>
    </Tab>

    <Tab title="Sol">
      <Container>
        <h3>Sol</h3>
      </Container>
      <div class="key-values key-values--footer-bottom">
        <KeyValue table label="Type de sol" type="input">
          <SelectExtended
            v-if="helperService.getReferentialByUid('typesol')"
            id="sol_type"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'typesol'
            )}/valeur`"
          />
        </KeyValue>
        <KeyValue table label="argile" type="input">
          <Input
            id="argile"
            :options="{ min: 0, step: 0.01 }"
            type="number"
            inputAfter="%"
          />
        </KeyValue>
        <KeyValue table label="Limon" type="input">
          <Input
            id="limon"
            :options="{ min: 0, step: 0.01 }"
            type="number"
            inputAfter="%"
          />
        </KeyValue>
        <KeyValue table label="Sable" type="input">
          <Input
            id="sable"
            :options="{ min: 0, step: 0.01 }"
            type="number"
            inputAfter="%"
          />
        </KeyValue>
        <KeyValue table label="Cailloux" type="input">
          <SelectExtended
            v-if="helperService.getReferentialByUid('cailloux')"
            id="cailloux"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'cailloux'
            )}/valeur`"
          />
        </KeyValue>
        <KeyValue table label="Profondeur de sol" type="input">
          <SelectExtended
            v-if="helperService.getReferentialByUid('profondeurdusol')"
            id="sol_profondeur"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'profondeurdusol'
            )}/valeur`"
          />
        </KeyValue>
        <KeyValue table label="Réserve utile" type="input">
          <SelectExtended
            v-if="helperService.getReferentialByUid('reserveutile')"
            id="reserve_utile"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'reserveutile'
            )}/valeur`"
          />
        </KeyValue>
        <KeyValue table label="Exposition">
          <Input id="exposition" />
        </KeyValue>
        <KeyValue table label="Sensibilité à la sécheresse" type="input">
          <Radio
            id="sensibilite_secheresse"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Sensibilité à l’excès d’eau" type="input"
          ><Radio
            id="sensibilite_exces_eau"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Irrigation" type="input">
          <Radio
            id="irrigation"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Drainage" type="input">
          <Radio
            id="drainage"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Sol en pente" type="input">
          <Radio
            id="sol_en_pente"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Analyse du sol disponible" type="input">
          <Radio
            id="analyse_sol_dispo"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
        <KeyValue table label="Date d’analyse du sol" type="input">
          <Input id="date_analyse_sol" type="date" />
        </KeyValue>
        <KeyValue table label=" Situation particulière">
          <Input id="situation_particuliere" type="textarea" />
        </KeyValue>
        <KeyValue table label="Analyse de sol" type="input">
          <InputMedia
            id="dat_media"
            type="file"
            textInfo="Fichier de 10Mo maximum"
            :media="parcel_sheet.media ?? null"
            @update:modelValue="setAnalyzeFile($event)"
            :defaultImage="true"
          />
        </KeyValue>
      </div>
    </Tab>

    <Tab title="Culture">
      <Container>
        <h3>Culture</h3>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Culture">
          <SelectExtended
            id="cultures"
            :apiParams="{ limit: 0, sort: 'nom.ASC' }"
            optionKey="uid"
            optionValue="nom"
            apiEndpoint="culture"
          />
        </KeyValue>
        <KeyValue table label="Variété">
          <Input id="variete" />
        </KeyValue>
        <KeyValue table label="Date de semis">
          <Input id="date_semis" type="date" />
        </KeyValue>
        <KeyValue table label="Densité de semis" value=" " />
        <KeyValue table child label="Unité densité de semis">
          <SelectExtended
            id="densite_semis"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="uid"
            optionValue="valeur"
            apiEndpoint="unite"
          />
        </KeyValue>

        <KeyValue table child label="Densité de semis">
          <Input
            id="densitesemis"
            type="number"
            :inputAfter="currentFormValues?.densite_semis?.value"
          />
        </KeyValue>
        <KeyValue table label="Ecartement des rangs">
          <Input
            id="ecartement_rangs"
            :options="{ min: 0, step: 0.01 }"
            type="number"
            inputAfter="cm" />
        </KeyValue>
        <KeyValue table label="Conditions de semis">
          <Input id="conditionssemis" />
        </KeyValue>

        <KeyValue table label="Objectif de rendement" value=" " />
        <KeyValue table child label="Unité objectifs">
          <SelectExtended
            id="unite"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="uid"
            optionValue="valeur"
            apiEndpoint="unite"
          />
        </KeyValue>
        <KeyValue table child label="Objectifs">
          <Input
            id="objectifs_rendement"
            type="number"
            :options="{ min: 0, step: 0.01 }"
            :inputAfter="currentFormValues?.unite?.value"
          />
        </KeyValue>

        <KeyValue table label="Date de levée">
          <Input id="date_levee" type="date" />
        </KeyValue>
        <KeyValue table label="Précédent culture">
          <SelectExtended
            id="culture_precedante"
            :apiParams="{ limit: 0, sort: 'nom.ASC' }"
            optionKey="uid"
            optionValue="nom"
            apiEndpoint="culture"
          />
        </KeyValue>
        <KeyValue table label="Travail du sol">
          <SelectExtended
            v-if="helperService.getReferentialByUid('travaildusol')"
            id="sol_travail"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'travaildusol'
            )}/valeur`"
          />
        </KeyValue>
        <KeyValue table label="Culture en production">
          <Radio
            id="cultureproduction"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            inputStyle="inline"
          />
        </KeyValue>
      </div>

      <Container>
        <h4>Fumure ou fertilisation</h4>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Engrais minéraux" value=" " />
        <KeyValue table child label="Date">
          <Input id="fumure_mineraux_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="fumure_mineraux_produits" type="textarea" />
        </KeyValue>
        <KeyValue table label="Engrais organiques" value=" " />
        <KeyValue table child label="Date">
          <Input id="fumure_organique_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="fumure_organique_produits" type="textarea" />
        </KeyValue>
        <KeyValue table label="Amendements calcaires" value=" " />
        <KeyValue table child label="Date">
          <Input id="fumure_calcaire_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="fumure_calcaire_produits" type="textarea" />
        </KeyValue>
        <KeyValue table label="Oligoéléments" value=" " />
        <KeyValue table child label="Date">
          <Input id="fumure_oligo_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="fumure_oligo_produits" type="textarea" />
        </KeyValue>
      </div>

      <Container>
        <h4>Protection des cultures</h4>
      </Container>
      <div class="key-values key-values--footer-bottom">
        <KeyValue table label="Herbicides" value=" " />
        <KeyValue table child label="Date">
          <Input id="protection_herbicides_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="protection_herbicides_produits" type="textarea" />
        </KeyValue>

        <KeyValue table label="Insecticides" value=" " />
        <KeyValue table child label="Date">
          <Input id="protection_inscticides_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="protection_insecticides_produits" type="textarea" />
        </KeyValue>

        <KeyValue table label="Fongicides" value=" " />
        <KeyValue table child label="Date">
          <Input id="protection_fongicides_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="protection_fongicides_produits" type="textarea" />
        </KeyValue>
        <KeyValue table label="Régulateurs" value=" " />
        <KeyValue table child label="Date">
          <Input id="protection_regulateurs_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="protection_regulateurs_produits" type="textarea" />
        </KeyValue>
        <KeyValue table label="Autres" value=" " />
        <KeyValue table child label="Date">
          <Input id="protection_autres_date" type="date" />
        </KeyValue>
        <KeyValue table child label="Produits / quantités">
          <Input id="protection_autres_produits" type="textarea" />
        </KeyValue>
      </div>
    </Tab>
  </Tabs>
</template>

<script>
/* eslint-disable no-param-reassign */
import { ref } from 'vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import InputMedia from '@/components/form/InputMedia.vue'

export default {
  name: 'parcelAddEdit',
  components: {
    InputMedia,
    KeyValue,
    Tabs,
    Tab,
    Input,
    Radio,
    SelectExtended,
    Container,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    parcel: {
      type: Object,
    },
    parcel_sheet: {
      type: Object,
    },
  },

  data() {
    const parcelTabs = ref(0)

    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().required(),
      nom_agriculteur: this.yup.string().nullable(),
      adresse: this.yup.string().nullable(),
      cp: this.yup.string().nullable(),
      ville: this.yup.string().nullable(),
      latitude: this.yup.string().nullable(),
      longitude: this.yup.string().nullable(),
      tel: this.yup.string().nullable(),
      email: this.yup.string().email().nullable(),
      cpmeteo: this.yup.string().nullable(),
      villemeteo: this.yup.string().nullable(),
      plateforme: this.yup.boolean().nullable(),
      media: this.yup.object().nullable(),

      sol_type: this.yup.object().nullable(),
      argile: this.yup.number().min('0').nullable(),
      limon: this.yup.number().min('0').nullable(),
      sable: this.yup.number().min('0').nullable(),
      cailloux: this.yup.object().nullable(),
      sol_profondeur: this.yup.object().nullable(),
      reserve_utile: this.yup.object().nullable(),
      sensibilite_secheresse: this.yup.boolean().nullable(),
      sensibilite_exces_eau: this.yup.boolean().nullable(),
      irrigation: this.yup.boolean().nullable(),
      drainage: this.yup.boolean().nullable(),
      sol_en_pente: this.yup.boolean().nullable(),
      exposition: this.yup.string().nullable(),
      date_analyse_sol: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      analyse_sol_dispo: this.yup.boolean().nullable(),
      situation_particuliere: this.yup.string().nullable(),

      cultures: this.yup.object().nullable(),
      variete: this.yup.string().nullable(),
      date_semis: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      densite_semis: this.yup.object().nullable(),
      densitesemis: this.yup.number().min('0').nullable(),
      ecartement_rangs: this.yup.number().min('0').nullable(),
      conditionssemis: this.yup.string().nullable(),
      objectifs_rendement: this.yup.number().min('0').nullable(),
      unite: this.yup.object().nullable(),
      date_levee: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      culture_precedante: this.yup.object().nullable(),
      sol_travail: this.yup.object().nullable(),
      cultureproduction: this.yup.boolean().nullable(),
      fumure_mineraux_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      fumure_mineraux_produits: this.yup.string().nullable(),
      fumure_organique_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      fumure_organique_produits: this.yup.string().nullable(),
      fumure_calcaire_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      fumure_calcaire_produits: this.yup.string().nullable(),
      fumure_oligo_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      fumure_oligo_produits: this.yup.string().nullable(),
      protection_herbicides_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      protection_herbicides_produits: this.yup.string().nullable(),
      protection_fongicides_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      protection_fongicides_produits: this.yup.string().nullable(),
      protection_inscticides_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      protection_insecticides_produits: this.yup.string().nullable(),
      protection_regulateurs_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      protection_regulateurs_produits: this.yup.string().nullable(),
      protection_autres_date: this.yup
        .date()
        .nullable()
        .transform((c, o) => (o === '' ? null : c)),
      protection_autres_produits: this.yup.string().nullable(),
    })

    // init du form
    const { errors, isSubmitting, currentFormValues } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
      currentFormValues,
      parcelTabs,
      method: 'post',
      data: {},
      endPoint: 'parcelle',
      essai: {},
      referentials: {},
    }
  },
  emits: ['post-data'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
  },
  created() {
    if (localStorage.getItem('referentials')) {
      this.referentials = JSON.parse(localStorage.getItem('referentials'))
    }
  },
  mounted() {
    if (this.parcel.id) {
      this.method = 'put'
      this.endPoint += `/${this.parcel.id}`
      this.loadData()
    }
  },
  methods: {
    handleValues(values) {
      this.emitter.emit('open-loader')
      console.log('values', values)
      // eslint-disable-next-line no-param-reassign
      values.media_id = values.media?.id ? values.media?.id : null
      values.cailloux_id = values.cailloux?.key
      values.sol_profondeur_id = values.sol_profondeur?.key
      values.reserve_utile_id = values.reserve_utile?.key
      values.culture_uid = values.cultures?.key
      values.densite_semis_uid = values.densite_semis?.key
      values.unite_uid = values.unite?.key
      values.culture_precedante_uid = values.culture_precedante?.key
      values.sol_travail_id = values.sol_travail?.key
      values.sol_type_id = values.sol_type?.key
      values.latitude = values.latitude ? values.latitude : null
      values.longitude = values.longitude ? values.longitude : null

      values.date_semis = this.helperService.formatDateForApi(
        values?.date_semis,
      )
      values.date_levee = this.helperService.formatDateForApi(
        values?.date_levee,
      )

      values.date_analyse_sol = this.helperService.formatDateForApi(
        values?.date_analyse_sol,
      )
      values.fumure_mineraux_date = this.helperService.formatDateForApi(
        values?.fumure_mineraux_date,
      )
      values.fumure_organique_date = this.helperService.formatDateForApi(
        values?.fumure_organique_date,
      )
      values.fumure_calcaire_date = this.helperService.formatDateForApi(
        values?.fumure_calcaire_date,
      )
      values.fumure_oligo_date = this.helperService.formatDateForApi(
        values?.fumure_oligo_date,
      )
      values.protection_herbicides_date = this.helperService.formatDateForApi(
        values?.protection_herbicides_date,
      )
      values.protection_fongicides_date = this.helperService.formatDateForApi(
        values?.protection_fongicides_date,
      )

      values.protection_inscticides_date = this.helperService.formatDateForApi(
        values?.protection_inscticides_date,
      )
      values.protection_regulateurs_date = this.helperService.formatDateForApi(
        values?.protection_regulateurs_date,
      )
      values.protection_autres_date = this.helperService.formatDateForApi(
        values?.protection_autres_date,
      )

      this.fetchService[this.method](this.endPoint, values).then(
        (reponse) => {
          this.data = reponse.data

          let ficheId = this.parcel_sheet.id
          if (this.method === 'post') {
            ficheId = this.data.fiche.id
          }

          values.media_id = values.dat_media?.id ? values.dat_media?.id : null

          this.fetchService
            .put(`parcelle/${this.data.id}/fiche/${ficheId}`, values)
            .then(
              () => {
                this.emitter.emit('close-loader')
                this.$emit('post-data', { type: this.method, id: this.data.id })
              },
              (error) => {
                this.emitter.emit('close-loader')
                this.formService.handleApiError(error)
              },
            )
        },
        (error) => {
          this.emitter.emit('close-loader')
          this.formService.handleApiError(error)
        },
      )
    },
    loadData() {
      const formData = { ...this.parcel, ...this.parcel_sheet }
      this.data = formData

      // Populate schema with data
      const schema = this.formService.populateShema(formData)

      if (this.parcel_sheet.sol_type) {
        schema.sol_type = {
          key: this.parcel_sheet.sol_type.id,
          value: this.parcel_sheet.sol_type.valeur,
        }
      }

      if (this.parcel_sheet.cailloux) {
        schema.cailloux = {
          key: this.parcel_sheet.cailloux.id,
          value: this.parcel_sheet.cailloux.valeur,
        }
      }
      if (this.parcel_sheet.sol_profondeur) {
        schema.sol_profondeur = {
          key: this.parcel_sheet.sol_profondeur?.id,
          value: this.parcel_sheet.sol_profondeur?.valeur,
        }
      }
      if (this.parcel_sheet.reserve_utile) {
        schema.reserve_utile = {
          key: this.parcel_sheet.reserve_utile?.id,
          value: this.parcel_sheet.reserve_utile?.valeur,
        }
      }
      if (this.parcel_sheet.culture) {
        schema.cultures = {
          key: this.parcel_sheet.culture?.uid,
          value: this.parcel_sheet.culture?.nom,
        }
      }
      if (this.parcel_sheet.densite_semis) {
        schema.densite_semis = {
          key: this.parcel_sheet.densite_semis?.uid,
          value: this.parcel_sheet.densite_semis?.valeur,
        }
      }
      if (this.parcel_sheet.unite) {
        schema.unite = {
          key: this.parcel_sheet.unite?.uid,
          value: this.parcel_sheet.unite?.valeur,
        }
      }
      if (this.parcel_sheet.culture_precedante) {
        schema.culture_precedante = {
          key: this.parcel_sheet.culture_precedante?.uid,
          value: this.parcel_sheet.culture_precedante?.nom,
        }
      }
      if (this.parcel_sheet.sol_travail) {
        schema.sol_travail = {
          key: this.parcel_sheet.sol_travail?.id,
          value: this.parcel_sheet.sol_travail?.valeur,
        }
      }

      if (this.parcel_sheet.date_semis) {
        schema.date_semis = this.parcel_sheet.date_semis.split(' ').shift()
      }
      if (this.parcel_sheet.date_analyse_sol) {
        schema.date_analyse_sol = this.parcel_sheet.date_analyse_sol
          .split(' ')
          .shift()
      }
      if (this.parcel_sheet.fumure_mineraux_date) {
        schema.fumure_mineraux_date = this.parcel_sheet.fumure_mineraux_date
          .split(' ')
          .shift()
      }
      if (this.parcel_sheet.fumure_organique_date) {
        schema.fumure_organique_date = this.parcel_sheet.fumure_organique_date
          .split(' ')
          .shift()
      }
      if (this.parcel_sheet.fumure_calcaire_date) {
        schema.fumure_calcaire_date = this.parcel_sheet.fumure_calcaire_date
          .split(' ')
          .shift()
      }
      if (this.parcel_sheet.fumure_oligo_date) {
        schema.fumure_oligo_date = this.parcel_sheet.fumure_oligo_date
          .split(' ')
          .shift()
      }
      if (this.parcel_sheet.protection_herbicides_date) {
        schema.protection_herbicides_date = this.parcel_sheet.protection_herbicides_date.split(' ').shift()
      }
      if (this.parcel_sheet.protection_inscticides_date) {
        schema.protection_inscticides_date = this.parcel_sheet.protection_inscticides_date.split(' ').shift()
      }
      if (this.parcel_sheet.protection_regulateurs_date) {
        schema.protection_regulateurs_date = this.parcel_sheet.protection_regulateurs_date.split(' ').shift()
      }
      if (this.parcel_sheet.protection_autres_date) {
        schema.protection_autres_date = this.parcel_sheet.protection_autres_date
          .split(' ')
          .shift()
      }

      // set form values
      this.formService.setFormValues(schema)
    },
    setPlanFile(event) {
      this.data.planFile = event
    },
    setAnalyzeFile(event) {
      this.data.analyzeFile = event
    },
    displayUnite() {},
  },
}
</script>
