<template>
  <form>
    <div class="view view--user">
      <div class="view-header">
        <div class="view-header__footer">
          <BtnBack
            class="back-btn"
            round
            grow
            icon="arrow-left"
          />
          <h1
            v-if="id"
            class="page-title"
            v-html="`Modifier la parcelle ${data.designation}`"
          ></h1>
          <h1 v-else class="page-title">Ajouter une parcelle</h1>
        </div>
      </div>

      <div class="view-body">
        <ParcelAddEdit
          v-model="submit"
          v-if="data?.id || id === null"
          :parcel="parcel"
          :parcel_sheet="parcel_sheet"
          @post-data="postData"
        />
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'parcels' }" text="Annuler" />
          <Btn @click="submit = true" text="Enregistrer" color="primary" />
        </div>
        <div class="view-actions view-actions--right">
          <Btn
            v-if="id && helperService.userHasPermission('parcelle_remove')"
            icon="trash-alt"
            hollow
            @click="modalDelete = true"
            text="Supprimer"
          />
        </div>
      </div>
    </div>
  </form>

  <!-- Modals -->
  <Modal
    title="Supprimer la parcelle&nbsp;?"
    :active="modalDelete"
    :data="data"
    @modal-close="modalDelete = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer la parcelle
        <b>{{ data.designation }}</b
        >?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalDelete = false" />
      <Btn
        text="Supprimer"
        @click="deleteParcel()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import Modal from '@/components/layout/Modal.vue'
import ParcelAddEdit from '@/views/componentsViews/parcel/ParcelAddEdit.vue'

export default {
  name: 'ParcelAddEditView',

  components: {
    ParcelAddEdit,
    Btn,
    BtnBack,
    Modal,
  },
  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      method: 'post',
      submit: false,
      endPoint: 'parcelle',
      data: {},
      modalDelete: false,
      id: null,
      parcel: {},
      parcel_sheet: {},
    }
  },

  created() {
    this.data.designation = ''
    this.id = this.$route.params.id ? Number(this.$route.params.id) : null
  },

  mounted() {
    if (this.id) {
      this.method = 'put'
      this.endPoint += `/${this.$route.params.id}`
      this.getData()
    }
  },

  methods: {
    getData() {
      this.emitter.emit('open-loader')
      this.fetchService
        .get(`parcelle/${this.id}`, {})
        .then(
          (response) => {
            this.data = response.data
            this.parcel = response.data
            this.parcel_sheet = response.data.fiche
          },
          (errorResponse) => {
            if (errorResponse.meta.status === 404) {
              this.$router.push({ name: 'notFound' })
            }
          },
        )
        .finally(() => this.emitter.emit('close-loader'))
    },
    deleteParcel() {
      this.emitter.emit('open-loader')
      this.fetchService.delete(`parcelle/${this.id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La parcelle a été supprimée.',
          })
          this.$router.push({ name: 'parcels' })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalDelete = false
    },
    postData(post) {
      if (post) {
        if (post.type === 'put') {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La parcelle a bien été mise à jour.',
          })
        } else if (this.$route.query?.from) {
          const params = { id: this.$route.query.e }
          this.$router.push({ name: this.$route.query.from, params, query: { upd: post.id } })
        } else {
          const params = {}
          this.$router.push({ name: 'parcels', params })
        }
      }
    },
  },
}
</script>
