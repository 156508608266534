<template>
  <div class="tabs"
    :class="{
      [`tabs--${layout}`]: layout
    }"
  >
    <div class="tabs-togglers" :class="togglersClass">
      <div class="tabs-togglers-buttons">
        <Btn
          v-for="(tab, i) of tabs"
          :key="i"
          :text="tab.props.title"
          :class="active === i ? 'active' : ''"
          @click="selectTab(i)"
        />
      </div>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { provide, computed, ref } from 'vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'Tabs',

  components: {
    Btn,
  },

  props: {
    modelValue: {
      type: [String, Number],
    },
    layout: {
      type: String,
    },
    togglersClass: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const active = computed(() => props.modelValue)
    const tabs = ref([])

    function selectTab(tab) {
      emit('update:modelValue', tab)
    }

    provide('tabsState', {
      active,
      tabs,
    })

    return {
      tabs,
      active,
      selectTab,
    }
  },
}
</script>

<style lang="scss" scoped>
.tabs {}

.tabs--simple {}

.tabs--sidebar {
  @include bp('sm') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $gutter-half;
  }

  @include bp('md') {
    grid-template-columns: 1fr 2fr;
  }
}

.tabs-content {

  .tabs--sidebar & {
    border-radius: $border-radius-base;
    @include shadow(1);
  }
}

.tabs-togglers-buttons {

  .tabs--simple & {
    display: flex;
    flex-wrap: wrap;
    gap: $gutter-half;
    @include container();
  }
}

.tabs-togglers {

  .tabs--simple & {
    margin-bottom: $gutter;
    @include v-padding($gutter-half);
    border-bottom: 1px solid $color-gray-lighter;

    .btn {
      color: $color-gray;
      border: 1px solid;
      border-radius: 100rem;
      font-weight: $font-weight-bold;
      @include h-padding($gutter);

      &.active {
        color: white;
        background-color: $color-primary-lighty;
        border-color: $color-primary-lighty;
      }
    }
  }

  .tabs--sidebar & {
    .btn {
      white-space: initial;
      padding-left: 2.4rem;
      text-align: left;

      @include bp('sm') {
        display: block;
        width: 100%;
      }

      &::before {
        content: "";
        display: block;
        @include size(1.2rem);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid $color-gray-light;
        border-radius: 50%;
      }

      &::after {
        @include bp('sm') {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: $color-gray-lighter;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.active {
        color: $color-primary-lighty;

        &::before {
          background-color: $color-primary-lighty;
          border-color: $color-primary-lighty;
        }
      }
    }
  }
}
</style>
