<template>
  <div class="input-block input-file" :class="{
    'input-block--required': required,
    'input-block--disabled': disabled,
    'input-block--error': errorMessage,
    'input-block--inline': inline,
  }">
    <label :for="id" v-if="label !== undefined && label !== ''">{{
      label
    }}</label>
    <div class="input" :class="getClasses">
      <template v-if="!loading">
        <UserAvatar v-if="type === 'avatar'" :key="componentKey" class="h-margin-auto" :image="file ?? null"
          :initials="avatarInitials" :size="avatarSize" :color="avatarColor" />
        <template v-else-if="isImage()">
          <ImageToken :image="file" :alt="mediaKey" :key="componentKey" :defaultImage="defaultImage"
            class="h-margin-auto" />
        </template>
      </template>
      <template v-else>
        <div class="fixed-ratio fixed-ratio--2by1" style="position: relative; width: 100%">
          <Loader active />
        </div>
      </template>

      <div class="input-file-filename">
        <span>{{ displayMediaName() }}</span>
        <Btn class="input-file-btn-remove" v-if="isUploaded" :key="componentKey" @click="deleteFile" color="default"
          icon="times-circle" size="xs" grow round />
      </div>

      <!-- Drag & drop -->
      <div class="drop" :class="getClasses" @dragover.prevent="dragOver" @dragleave.prevent="dragLeave"
        @drop.prevent="select($event)">
        <h1 style="margin-bottom: 0">Glisser-déposer votre fichier ici</h1>
      </div>
      <div class="input-file-label">
        <div class="input">
          <label class="input-file-label">
            <input :key="componentKey" class="input-file-input"
              v-if="!file || (file && $route.params.id) || avatarFile || media" :id="id" type="file" ref="inputFile"
              :accept="getAcceptedFiles()" :name="name ? name : id" :disabled="disabled" @change="select" />
            <Btn class="input-file-btn-add" :text="loading
              ? `En cours de téléversement... ${progress}%`
              : `Choisir ${type !== 'file' && type !== 'plan' && type !== 'import' ? 'une image' : 'un fichier'}`
              " color="default" icon="plus" :disabled="loading" />
          </label>

          <div class="input-text input-text--info" v-if="textInfo && !errorMessage">
            {{ textInfo }}
          </div>
          <div class="input-text input-text--error" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar.vue'
import Btn from '@/components/base/Btn.vue'
import { useField } from 'vee-validate'
import Loader from '@/components/layout/Loader.vue'
import axios from 'axios'
import ImageToken from '@/components/base/ImageToken.vue'

export default {
  name: 'InputMedia',

  components: {
    ImageToken,
    Loader,
    Btn,
    UserAvatar,
  },

  props: {
    id: {
      type: String,
      default: 'input-file-upload',
    },
    // Entity, logo, picto etc.
    media: {
      type: Object,
    },
    // Type of upload (avatar, logo, picto)
    type: {
      type: String,
      default: 'avatar',
    },
    // Property to be updated
    mediaKey: {
      type: [String, Number],
      default: 'logo_prim_id',
    },
    // Avatar
    avatarFile: {
      type: Object,
    },
    avatarSize: {
      type: String,
      default: 'normal',
    },
    avatarColor: {
      type: [String, Number],
      default: '18',
    },
    avatarInitials: {
      type: String,
    },
    // Button
    buttonText: {
      type: String,
      default: 'Supprimer le fichier',
    },
    // Input
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    required: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    defaultImage: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const {
      errorMessage,
      handleBlur,
      handleChange,
    } = useField(props.id)

    return {
      errorMessage,
      handleBlur,
      handleChange,
      chunks: [],
      chunkSize: 0,
      numberOfChunks: 0,

      // Data updated to perform a component reload
      index: 1,
      componentKey: 0,

      // Object
      file: null,
      // Keep the file is if the initial in the props
      initialFile: true,
      // Data of the uploaded file
      fileUploadData: null,
      // Determine if the file is already uploaded
      isUploaded: false,
      // Percent of the upload
      uploaded: 0,
      loading: false,

      isDragging: false,
    }
  },

  watch: {
    chunks: {
      deep: true,
      handler(n) {
        if (n.length > 0) {
          this.upload()
        }
      },
    },
  },

  computed: {
    progress() {
      return Math.floor((this.uploaded / this.fileUploadData.size) * 100)
    },
    formData() {
      const chunks = Math.ceil((this.fileUploadData.size / 1024) / 1024)

      const data = {
        morceau: this.chunks[0],
        position: this.index,
        meta: {
          nom: this.fileUploadData.nom,
          taille: Math.round(this.fileUploadData.size / 1024),
          extension: this.fileUploadData.nom.split('.').pop().toLowerCase(),
          longueur: chunks,
          type_uid: this.type === 'file' || this.type === 'import' ? 'fichier' : this.type,
        },
      }

      return this.fileService.setData(data)
    },
    config() {
      return {
        method: 'POST',
        url: `${process.env.VUE_APP_ROOT_API}utilitaire/media/televerser`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$store.state.auth.user.token}`,
        },
        data: this.formData,
        onUploadProgress: (event) => {
          this.uploaded += event.loaded
        },
      }
    },
    getClasses() {
      return {
        isDragging: this.isDragging,
        isUploaded: this.isUploaded,
      }
    },
  },

  mounted() {
    this.file = this.media || null

    if (this.file) {
      this.isUploaded = true
    }
  },

  // For avatar
  updated() {
    if (this.type === 'avatar') {
      this.file = this.media || null

      if (this.file) {
        this.isUploaded = true
      }
    }
  },

  methods: {
    /**
     * Dragging event
     */
    dragOver() {
      this.isDragging = true
    },
    dragLeave() {
      this.isDragging = false
    },

    /**
     * Process the file
     * @param event
     */
    async select(event) {
      this.isDragging = false

      if (this.isUploaded) {
        await this.deleteFile()
      }

      this.file = 'dataTransfer' in event ? event.dataTransfer.files[0] : event.target.files.item(0)
      this.file.nom = this.file.name
      delete this.file.name

      this.fileUploadData = this.file

      const alert = (text) => this.emitter.emit('alert', {
        type: 'warning',
        content: text,
      })

      const checkUploadedFile = (fileSizeInMegabytes, validExtensions) => {
        if (this.file.size > this.fileService.formatMegaBytes(fileSizeInMegabytes)) {
          alert(`La taille du fichier ne peut pas être supérieure à ${fileSizeInMegabytes}Mo`)
          this.resetForm()
        } else if (this.file.size < 1000) {
          alert('La taille du fichier ne peut pas être inférieure à 1Ko')
          this.resetForm()
        } else if (
          validExtensions !== '*' && !this.fileService.hasExtensions(this.file, validExtensions)
        ) {
          alert(`L'extension est invalide, le type de fichier attendu est : ${validExtensions.join(', ')}`)
          this.resetForm()
        } else {
          this.createChunks()
        }
      }
      switch (this.type) {
        case 'avatar':
        default:
          checkUploadedFile(1, ['jpg', 'jpeg', 'png'])
          break
        case 'logo':
          checkUploadedFile(2, ['jpg', 'jpeg', 'png', 'svg'])
          break
        case 'picto':
          checkUploadedFile(1, ['svg'])
          break
        case 'photo':
          checkUploadedFile(5, ['jpg', 'jpeg'])
          break
        case 'file':
          checkUploadedFile(10, '*')
          break
        case 'plan':
          checkUploadedFile(10, '*')
          break
        case 'import':
          checkUploadedFile(5, ['xls', 'xlsx'])
          break
      }
    },
    /**
     * Create chunks to upload the file
     */
    createChunks() {
      this.chunkSize = Math.min(this.file.size / 1024, 1024)
      this.numberOfChunks = Math.ceil((this.file.size / 1024) / this.chunkSize)

      this.loading = true
      for (let i = 0; i < this.numberOfChunks; i += 1) {
        this.chunks.push(this.file.slice(
          i * (this.chunkSize * 1024),
          (i + 1) * (this.chunkSize * 1024),
        ))
      }
    },
    /**
     * Upload the file
     */
    upload() {
      axios(this.config)
        .then((response) => {
          this.chunks.shift()
          this.index += 1

          this.file = response.data.data
          if (response.status === 201) {
            this.updateInput(response.data.data)

            this.fileUploadData = this.file
            this.initialFile = false
            this.loading = false
            this.isUploaded = true
            this.isDragging = false
          }
        }).catch((error) => console.log(error))
    },
    /**
     * Update the emit event to be processed by the view
     * @param event
     */
    updateInput(event) {
      this.handleChange(event)
      this.$emit('update:modelValue', event)
    },
    /**
     * Delete file
     */
    async deleteFile() {
      if (this.initialFile) {
        this.updateInput(null)
        this.resetForm()
        this.file = null
      } else {
        await this.fetchService.delete(`utilitaire/media/${this.file.id}`)
        this.updateInput(null)
        this.resetForm()
      }
    },
    /**
     * Check if the file type is an image
     * @returns {boolean}
     */
    isImage() {
      if (this.isUploaded) {
        return ['jpg', 'jpeg', 'png', 'svg'].includes(this.file.extension ?? this.fileUploadData.extension)
      }
      return ['picto', 'photo', 'logo'].includes(this.type)
    },
    /**
     * Display the media name
     * @returns {*|string}
     */
    displayMediaName() {
      const file = this.fileUploadData ?? this.file
      const name = this.fileUploadData?.nom ?? this.file?.nom
      return file ? name : 'Aucun fichier choisi'
    },
    /**
     * Returns the accepted files for a type
     * @returns {string}
     */
    getAcceptedFiles() {
      return this.type === 'plan' || this.type === 'file' || this.type === 'import' ? null : 'image/*'
    },
    /**
     * Reset all the values processed for the upload
     */
    resetForm() {
      this.chunks = []
      this.index = 1
      this.chunkSize = 0
      this.numberOfChunks = 0
      this.componentKey += 1
      this.file = null
      this.fileUploadData = null
      this.isUploaded = false
    },
  },
}
</script>

<style lang="scss" scoped>
/* INPUT IMAGE */

.input-file {}

.input {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.input-file-label {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.input-text.input-text--info {
  z-index: 1
}

.user-avatar {
  margin-bottom: $gutter-half;
}

.input-file-filename {
  margin-bottom: $gutter-half;

  >span {
    font-family: monospace;
    word-break: break-all;
  }
}

.input-file-btn-add {
  pointer-events: none;
}

.input-file-btn-remove {}

.drop {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  opacity: 0;
}

.input.isUploaded .drop {
  z-index: -1;
}

.input.isDragging .drop.isDragging {
  background-color: $color-primary;
  border-width: 2px;
  border-color: #fff;
  border-style: dashed;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

$file-size: 8rem;

.file {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size($file-size);
  background-position: 50% 50%;
  background-size: cover;
}
</style>
